@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Sansation';
  src: local('Sansation'), url('./../fonts/Sansation_Regular.ttf')
}

html, body {
  background-color: #F6F8FB;
}

body {
  margin: 0;
  font-family: 'Sansation', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

@media (prefers-color-scheme: dark) {
  html, body {
    background-color: #0C1421; /* Couleur adaptée pour le mode sombre */
  }
}